<template>
  <div>
    <h1 :class="$style.pageTitle">
      <template v-if="isCreate">おしらせ作成</template>
      <template v-else>おしらせ編集</template>
    </h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>

            <a-form-model
              v-else
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-form-model-item ref="title" label="タイトル" prop="title">
                <a-input
                  v-model="form.title"
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item ref="category" label="カテゴリ" prop="category">
                <a-input
                  v-model="form.category"
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item ref="date" label="日付" prop="date">
                <a-config-provider :locale="locale">
                  <a-date-picker v-model="form.date" />
                </a-config-provider>
              </a-form-model-item>
              <a-form-model-item ref="description" label="本文" prop="description">
                <a-textarea v-model="form.description" :rows="10"/>
              </a-form-model-item>
              <a-form-model-item ref="is_public" label="公開設定" prop="is_public">
                <a-radio-group v-model="form.is_public">
                  <a-radio :value="0">非公開</a-radio>
                  <a-radio :value="1">公開</a-radio>
                </a-radio-group>
              </a-form-model-item>

              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <div class="clearfix">
                  <div v-if="!isCreate" class="float-left">
                    <a-button type="danger" :loading="btnLoading" @click="onDelete">
                      削除
                    </a-button>
                  </div>
                  <div :class="{ 'float-right': !isCreate }">
                    <a-button type="primary" @click="onSubmit" :class="$style.filledBtn" :loading="btnLoading">
                      {{ submitString }}
                    </a-button>
                    <router-link :to="backPath">
                      <a-button style="margin-left: 10px;">
                        戻る
                      </a-button>
                    </router-link>
                  </div>
                </div>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import { objectData } from '@/services/object'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  name: 'FormNotification',
  data() {
    return {
      locale: jaJa,
      loading: true,
      btnLoading: false,
      backPath: '/master/notifications/list',
      notification: Object.assign({}, objectData.notification),
      form: {
        title: '',
        category: '',
        date: moment(),
        description: '',
        is_public: 0,
      },
      rules: {
        title: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1024, message: '1024文字までです。', trigger: 'blur' },
        ],
        category: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1024, message: '1024文字までです。', trigger: 'blur' },
        ],
        date: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        description: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        is_public: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
      },
    }
  },
  computed: {
    // 作成画面ならばtrue、編集画面ならばfalse
    isCreate() {
      return this.$route.meta.submitType !== 'put'
    },
    submitString() {
      return this.isCreate ? '作成' : '更新'
    },
  },
  watch: {
    notification: function(val) {
      this.form.title = val.title
      this.form.category = val.category
      this.form.date = moment(val.date)
      this.form.description = val.description
      this.form.is_public = val.is_public
    },
  },
  methods: {
    // 作成または更新ボタン押下時
    onSubmit() {
      this.btnLoading = true
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          this.btnLoading = false
          return false
        }

        Vue.prototype.$api.send(
          this.isCreate ? 'post' : 'put',
          'notifications' + (this.isCreate ? '' : ('/' + this.$route.params.id)),
          {
            title: this.form.title,
            category: this.form.category,
            date: this.form.date.format('YYYY-MM-DD'),
            description: this.form.description,
            is_public: this.form.is_public,
          },
        )
          .then(response => {
            this.$notification['success']({
              message: 'お知らせを' + this.submitString + 'しました。',
            })
            this.$router.push(this.backPath)
          })
          .catch(error => {
            if (error.status === 406 || error.status === 405 || error.status === 403 || error.status === 400) {
              this.$notification['error']({
                message: error.data.data,
              })
            } else if (error.data && error.data.errors && error.data.errors.length) {
              for (const key in error.data.errors) {
                this.$refs[key].validateState = 'error'
                this.$refs[key].validateMessage = error.data.errors[key]
              }
            } else {
              this.$notification['error']({
                message: error.status + ': ' + this.submitString + 'に失敗しました。',
              })
            }
            this.btnLoading = false
          })
      })
    },
    // 削除ボタン押下時
    onDelete() {
      this.btnLoading = true
      Vue.prototype.$api.send('delete', 'notifications/' + this.$route.params.id)
        .then(response => {
          this.$notification['success']({
            message: 'お知らせを削除しました。',
          })
          this.$router.push(this.backPath)
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 削除に失敗しました。',
          })
          this.btnLoading = false
        })
    },
  },
  created() {
    if (this.isCreate) {
      this.loading = false
      return
    }

    Vue.prototype.$api.send('get', 'notifications/show/' + this.$route.params.id)
      .then(response => {
        this.notification = response
        this.loading = false
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': お知らせデータの取得に失敗しました。',
        })
        this.$router.push(this.backPath)
      })
  },
}
</script>
